@import './flex-grid.scss';

body,
html {
  font-size: 15px;
  min-height: 100%;
  height: 100%;
  font-weight: 300;
  scroll-behavior: smooth;
}
body {
  position: relative;
  background: #fff;
  font-family: 'Roboto', 'Arial', sans-serif;
}
a {
  color: #0076e0;
  text-decoration: none;
  &:hover,
  &:focus {
    color: #0a6cc2;
    text-decoration: none;
  }
}
a,
.transition {
  transition: all linear 0.4s;
}

h1 {
  font-size: 40px;
  font-weight: 600;
}
h2 {
  font-size: 28px;
  font-weight: 400;
  margin: 7px 0 14px;
}
h3 {
  font-size: 22px;
}
h4 {
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 8px;
}
p {
  font-size: 18px;
}
.nowrap {
  white-space: nowrap;
}
@media screen and (min-width: --xs-width) {
  &.desktop-nowrap {
    white-space: nowrap;
  }
}
.hidden {
  display: none !important;
}
.showonload {
  opacity: 0;
  transition: opacity 1s;
  &.loaded {
    opacity: 1;
  }
}
.site {
  min-height: 100%;
  padding-top: 95px;
  overflow-x: hidden;
  background-color: #fff;
}
.logo {
  display: block;
  width: 200px;
  height: 37px;
  &a {
    opacity: 1;
  }
  &-header {
    height: auto !important;
    font-size: 12px;
    text-align: center;
    padding-top: 30px;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  border: none;
  top: 0;
  padding: 33px 0;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  background-color: #0b5ea8;
  color: #fff;
  padding: 1.9em 0;
  font-size: 17px;
  -webkit-box-shadow: 0 6px 10px -11px rgba(0, 0, 0, 0.6);
  box-shadow: 0 6px 10px -11px rgba(0, 0, 0, 0.6);

  &-login {
    .logo {
      top: 17px !important;
    }
  }

  * {
    color: #fff;
  }
  .desktop &-top {
    position: fixed;
    z-index: 2;
  }

  .menu {
    display: inline-block;
    &-links {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        margin: 0.35em 11px;
        text-transform: uppercase;
      }
    }
  }
  .menu-link {
    color: #fff;
    font-weight: 400;
    text-decoration: none;
    font-size: 14px;
    &:hover,
    &:focus {
      color: #fff;
      text-decoration: underline;
    }
  }
  &-contents {
    padding-left: 290px;
    padding-right: 290px;
  }

  .logo {
    position: absolute;
    width: 146px;
    height: 24px;
    z-index: 2;
    left: 45px;
    top: 20px;
    background: url(../img/logo.png) 0 0 no-repeat;
    -webkit-background-size: 146px 24px;
    background-size: 146px 24px;
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
      only screen and (min-resolution: 144dpi) {
      & {
        background-image: url(../img/logo2x.png);
      }
    }
  }
  .phone-block {
    position: absolute;
    top: 0;
    right: 2em;
    z-index: 2;
    text-align: right;
    padding: 1.8em 0;
  }
  .phone-number {
    font-size: 1.6em;
    font-weight: 300;
  }
  &-link {
    display: inline-block;
    border-bottom: 1px dashed #83b7e5;
    text-decoration: none;
    opacity: 0.8;
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
  a {
    color: #fff;
  }
}

.footer {
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #363636;
  padding: 1.4em 0;

  &-contents {
    display: flex;
    justify-content: center;
    align-items: center;

    text-align: right;
    text-align: center;
    color: #999;

    > a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }
}

/* icons */
.icon {
  display: inline-block;
  width: 100px;
  height: 100px;
  background-image: url(../img/icons.png);
  background-size: 400px 200px;
  transition: all ease-in 0.45s;
  &-complex {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 0;
    transition: all ease-in 0.45s;
    .ready & {
      height: 100px;
    }
  }
  &-quality {
    .icon-main {
      background-position: 0 0;
      transition-delay: 0.5s;
    }
    .icon-fill {
      background-position: 0 -100px;
      transition-delay: 1s;
    }
  }
  &-prices {
    .icon-main {
      background-position: -100px 0;
      transition-delay: 1s;
    }
    .icon-fill {
      background-position: -100px -100px;
      transition-delay: 1.5s;
      transform-origin: 90% 90%;
    }
  }
  &-estimates {
    .icon-main {
      background-position: -200px 0;
      transition-delay: 1.5s;
    }
    .icon-fill {
      background-position: -200px -100px;
      transition-delay: 2s;
      transform-origin: 15px 15px;
    }
  }
  &-trust {
    .icon-main {
      background-position: -300px 0;
      transition-delay: 2s;
    }
    .icon-fill {
      background-position: -300px -100px;
      transition-delay: 2.5s;
    }
  }
  &-fill,
  &-main {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url(../img/icons.png);
    background-size: 400px 200px;
    transform: scale(0);
    opacity: 0;
    .ready & {
      transform: scale(1);
      opacity: 1;
    }
  }
  &-fill {
    z-index: 1;
    transition-duration: 0.4s;
  }
  &-main {
    z-index: 1;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-resolution: 144dpi) {
  .icon {
    background-image: url(../img/icons2x.png);
  }
}

/* homepage */
.section {
  padding: 100px 0;
  transition: all ease-in 0.45s;
  background-color: #fff;
  h2,
  .bottom-link {
    text-align: center;
  }
  p {
    text-align: center;
  }
  .bottom-link {
    margin: 25px 0 40px;
    font-size: 17px;
    a {
      text-decoration: none;
      color: #d50321;
      border-bottom: 1px solid lighten(#d50321, 15%);
      &:hover,
      a:focus {
        border: none;
      }
    }
  }
  a {
    &:hover,
    a:focus {
      border: none;
    }
  }
  &.parallax {
    padding: 55px 0;
  }
  .form {
    font-size: 18px;
  }
  .buttons {
    text-align: center;
  }
}

.buttons-centered {
  padding: 30px 0;
  text-align: center;
}

.top-section {
  position: relative;
  min-height: 460px;
  color: #fff;
  text-align: center;
  background: url(../img/top-back.jpg) no-repeat center center;
  padding-bottom: 55px;
  background-size: cover;
  .row {
    justify-content: space-between;
    position: relative;
    top: 7vh;
  }
  .packbob {
    min-width: 370px;
    min-height: 380px;
    img {
      max-width: 100%;
    }
  }
  .slogan {
    text-align: right;
    h1 {
      margin-bottom: 0.3em;
      text-shadow: -2px -2px 3px rgba(0, 0, 0, 0.5);
    }
    p {
      font-size: 26px;
      line-height: 1.4em;
      margin: 0.3em 0 1.6em;
    }
    @media screen and (min-width: --m-width) {
      & {
        padding-left: 3em;
      }
    }
  }
  .desktop & {
    position: relative;
    min-height: calc(100vh '-' 100px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .slogan {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
    }
  }
}

.calculator-section {
  background: #f8f8f8 url(../img/bag-back.png) no-repeat left center;
  padding-bottom: 70px;
  h2,
  p {
    padding: 0 20px;
  }
  .container {
    margin: 0 auto 0 350px;
  }
  .form {
    max-width: 720px;
    margin: 40px auto 10px;
    .form-row {
      margin-bottom: 28px;
      white-space: nowrap;
    }
    .form-label {
      display: inline-block;
      width: 105px;
      &-long {
        width: 188px;
      }
    }
    .form-value {
      max-width: 102px;
      .form-control {
        width: 100%;
        margin-right: 7px;
        font-size: inherit;
      }
    }
  }
  .form-control {
    padding: 13px 12px;
    text-align: center;
  }
  .calculated-price {
    margin: 10px auto 32px;
    color: #d50321;
    font-size: 22px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
    transform: scale(0);
    opacity: 0;
    transition: all ease-in 0.4s;
    .value {
      font-size: 1.3em;
    }
    .unit {
      font-size: 0.8em;
    }
  }
  .button {
    pointer-events: none;
    background-color: #999;
    border-bottom: 2px solid rgb(141, 135, 135) !important;
  }
  &.counted {
    .calculated-price {
      opacity: 1;
      transform: scale(1);
      transition: all ease-in 0.5s;
    }
    .button {
      pointer-events: none;
      background-color: #999;
    }
  }
  &.changed {
    .button {
      pointer-events: auto;
      background-color: #d50321;
      border-bottom: 2px solid #bb0621 !important;
    }
  }
}
.call-order-section {
  background: #3b3a39 url(../img/call-order-back.jpg) no-repeat center center;
  color: #fff;
  background-size: cover;
  .buttons {
    padding-top: 20px;
  }
  h2 {
    -webkit-font-smoothing: antialiased;
  }
  .to-catalog-link {
    text-align: center;
    a {
      color: #fff;
      opacity: 0.7;
    }
  }
}
.reasons-section {
  .col {
    margin-top: 30px;
    text-align: center;
    padding: 0 0.5%;
  }
  p {
    font-size: 16px;
  }
}
.mission-section {
  padding: 0;
  background-size: cover;
  background-color: #034c8e;
  color: #fff;
  padding: 0;
  &.parallax {
    padding: 0;
  }
  h2 {
    text-align: left;
  }
  p {
    text-align: left;
    font-size: 22px;
    line-height: 34px;
  }
  .image-part {
    background: #00a327 url(../img/cleaner-world.jpg) no-repeat center center;
    position: relative;
    left: 10%;
    background-size: cover;
  }
  .text-part {
    padding: 80px 0 70px;
  }
}
.contact-section {
  padding-bottom: 80px;
  &.parallax {
    padding-bottom: 40px;
  }
  .row {
    justify-content: space-between;
  }
  .fa {
    width: 30px;
    color: #999;
  }
  .property {
    margin: 20px 0;
    font-size: 22px;
  }
  .property-value {
    display: inline-block;
    width: 80%;
    vertical-align: top;
  }
}
.map-section {
  position: relative;
  padding: 0;
  &.parallax {
    padding: 0;
  }
  iframe {
    width: 100%;
    height: 375px;
  }
  &-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 2;
    &.hidden {
      z-index: -1;
    }
  }
}
.share-section.section {
  text-align: center;
  padding-top: 35px;
  background-color: #f2f2f2;
  .social-buttons {
    display: inline-block;
  }
}

/* popups */
.popup-overlay {
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  width: 100vw;
  height: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.35);
  display: flex;
  align-items: center;
  justify-items: center;
  transition: all 0.2s;

  &.popup-opened {
    z-index: 11;
    .popup-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 11;
      opacity: 1;
      transform: scale(1);
      transition-duration: 0.35s;
      transition-delay: 0.1s;
      transition: all 0.45s ease-out;
    }
  }
}

.popup-form {
  height: auto;
  min-height: 170px;
  margin: auto;
  background: #fff;
  color: #000;
  z-index: 1;
  transition: all 0.4s ease-in;
  opacity: 0;
  transform: scale(0.5);
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &-heading {
    font-size: 28px;
    margin: 15px 0;
    font-weight: 400;
  }
  p {
    margin: 0 0 25px;
  }
  .send-form-row {
    width: 320px;
    margin-bottom: 16px;
  }
  .form-row.input .placeholder {
    color: #999;
  }
  .close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 32px;
    color: #837e7e;
    padding: 0 10px;
    opacity: 0.6;
    transition: opacity 0.3s;
    &:hover {
      opacity: 1;
    }
  }
  &.order-call-form {
    min-height: 380px;
  }
  .sending,
  .sended,
  .finished {
    text-align: center;
  }
  .current-item-name {
    display: block;
    width: 100%;
    padding: 15px;
    font-size: 16px;
    cursor: default;
    border: 1px solid #034c8e;
    border-radius: 0;
    resize: none;
    transition: all 0.3s;
    background: #ececec;
  }
}

/* catalog page */
.catalog-section {
  background-color: #f9f9f9;
  .col {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  h2 {
    text-align: left;
    .note {
      display: inline-block;
      position: relative;
      width: 100%;
      color: #555;
      font-size: 19px;
      font-weight: 300;
      padding-left: 26px;
      left: -26px;
      margin: 20px 0;
      line-height: 25px;
      .fa {
        position: absolute;
        top: 4px;
        left: 0;
        font-size: 20px;
        color: #333;
      }
      .highlighted {
        color: #333;
        font-weight: 400;
      }
      @media screen and (max-width: 968px) {
        font-size: 15px;
        line-height: 23px;
        padding-left: 20px;
        left: -20px;
        margin: 14px 0;
        .fa {
          font-size: 17px;
          top: 2px;
        }
      }
    }
  }
}
.card {
  background-color: #fff;
  padding: 20px;
  font-size: 17px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  h4 {
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 400;
    color: #0076e0;
    font-size: 20px;
  }
  display: flex;
  justify-content: space-between;
  .item-photo {
    width: 35%;
    max-width: 180px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .item-description {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    a {
      border-bottom: 1px solid #2e9cff;
      &.mp-button {
        border: none;
      }
    }
  }
  .item-properties {
    line-height: 26px;
  }
  .item-price {
    color: #999;
    padding: 5px 0;
  }
  .control-buttons {
    margin-top: auto;
    margin-top: 15px;
    .decrease,
    .increase {
      display: inline-block;
      padding: 4px 8px;
      cursor: pointer;
      font-size: 20px;
      opacity: 0.6;
      &:hover,
      &:focus {
        opacity: 1;
      }
    }
    .form-control {
      width: 87px;
      padding: 9px 14px;
      text-align: center;
      font-size: 17px;
    }
    .bottom-part {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .summa {
      font-size: 24px;
      padding: 6px 0;
    }
  }
}

/* Order panel */
.order-panel {
  position: fixed;
  width: 100%;
  right: 0;
  left: 0;
  padding-top: 85px;
  bottom: -3000px;
  color: #000;
  transition: all 0.4s;
  z-index: 10;
  &-top {
    position: absolute;
    padding: 26px 0 18px;
    background-color: rgba(55, 52, 55, 0.95);
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    color: #fff;
    min-height: 85px;
    box-sizing: border-box;
    .top-contents {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
    }
    .added {
      font-size: 20px;
    }
  }
  &-content {
    width: 100%;
    background-color: #fff;
    overflow-y: auto;
    display: none;
    .items {
      overflow-y: auto;
      max-height: 200px;
    }
    .item {
      display: flex;
      position: relative;
      align-items: baseline;
      justify-content: space-between;
      top: -30px;
      @media screen and (max-width: 968px) {
        & {
          top: -17px;
        }
      }
      &-photo {
        position: relative;
        top: 40px;
        width: 80px;
        height: 80px;
        margin-right: 10px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .form-control {
        height: auto;
        max-width: 80px;
        margin: 0 10px;
      }
      &.added {
        .button {
          display: none;
        }
      }
      &-description {
        max-width: 56%;
      }
    }
  }
  .move-panel {
    cursor: pointer;
    opacity: 0.8;
    transition: opacity 0.35s;
    padding: 7px 0;
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
  .info-and-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .button {
      align-self: flex-end;
      margin-left: 15px;
    }
    .total-qty,
    .total-price {
      white-space: nowrap;
    }
  }
  .summa {
    font-size: 20px;
    min-width: 112px;
  }
  .remove-item {
    padding: 4px 10px;
    cursor: pointer;
  }
}
.with-order .order-panel {
  bottom: 0;
  height: 85px;
  max-height: 85px;
  &-content {
    display: block;
  }
  .collapse-link {
    display: none;
  }
  &.opened {
    height: auto;
    max-height: 40%;
    .expand-link {
      display: none;
    }
    .collapse-link {
      display: inline-block;
    }
  }
}

.mp {
  &-container {
    position: relative;
    max-width: 1160px;
    margin: 0 auto;
    padding: 0 40px;
    box-sizing: border-box;
  }
  &-section {
    padding: 40px 0 55px 0;
    &-dark {
      color: #ffffff;
    }
    &-header {
      margin-bottom: 50px;
    }
    &-content {
    }
    &-title {
      margin-top: 0;
      margin-bottom: 20px;
      font-size: 38px;
      text-align: center;
    }
    &-subtitle {
      margin-top: 0;
      margin-bottom: 45px;
      font-size: 22px;
      font-weight: normal;
      text-align: center;
    }
  }
  &-form {
    padding: 20px 35px;
    background-color: rgba(255, 255, 255, 0.8);
    color: #000000;
    &-title {
      margin-bottom: 20px;
      font-size: 24px;
      text-align: center;
      &-question {
        text-align: left;
        margin-bottom: 5px;
      }
      &-format {
        display: block;
        font-size: 18px;
      }
    }
    &-group {
      margin-bottom: 25px;
      &-last {
        margin-bottom: 15px;
      }
    }
    &-control {
      display: block;
      width: 100%;
      padding: 15px;
      font-size: 16px;
      background-color: #fff;
      border: 1px solid #034c8e;
      border-radius: 0;
      resize: none;
      transition: all 0.3s;
      font-family: 'Roboto', 'Arial', sans-serif;
      outline: none;

      &-select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: #ececec;
      }
      &:focus {
        background-color: #ddf0fb;
        transition: all 0.1s;
      }
      &.with-error {
        border-color: #d50321;
      }
      .PhoneInputCountry {
        display: none;
      }
      &.PhoneInput {
        font-size: initial;
        text-align: left;
        &--focus {
          background-color: #ddf0fb;
          transition: all 0.1s;
          input {
            background-color: #ddf0fb;
            transition: all 0.1s;
          }
        }
        input {
          border: none;
          outline: none;
          font-size: 16px;
        }
      }
    }
    &-info {
      margin: 0;
      padding: 0 15px;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      color: #5d5d5d;
    }
    &-subtitle {
      font-size: 12px;
      line-height: 18px;
      text-align: left;
    }
  }
  &-popup-form {
    max-width: 470px;
    margin: 0 auto;
  }
  &-link {
    color: #0076e0;
    transition: all 0.3s;
    &:focus,
    &:hover {
      color: #053969;
      transition: all 0.1s;
    }
    &-underline {
      border-bottom: 1px solid #0076e0;
      &:focus,
      &:hover {
        border-color: #053969;
      }
    }
  }
  &-button {
    display: inline-block;
    margin: 0;
    padding: 15px;
    text-align: center;
    background-color: #034c8e;
    color: #ffffff;
    font-size: 20px;
    font-weight: normal;
    border-radius: 0;
    border: none;
    transition: all 0.3s;
    cursor: pointer;
    text-decoration: none;
    line-height: 1.1;
    &:focus,
    &:hover {
      color: #ffffff;
      background-color: #053969;
      transition: all 0.1s;
    }
    &-block {
      display: block;
      width: 100%;
    }
    &-small {
      font-size: 16px;
      padding: 15px 0;
    }

    &.button-send:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  /* hero */
  &-hero {
    background-image: url('../img/magpack-top-bg.jpg');
    background-size: cover;
    &-title {
      margin-bottom: 75px;
    }
    &-content {
      display: flex;
      &-info {
        flex-grow: 1;
        margin-right: 80px;
      }
    }
    &-form {
      width: 305px;
      flex-shrink: 0;
    }
    &-arrow {
      display: flex;
      margin-bottom: 60px;
      height: 95px;
      box-sizing: border-box;
      font-size: 24px;
      font-weight: bold;
      color: #000;
      span {
        display: flex;
        align-items: center;
        flex-grow: 1;
        padding: 10px 20px;
        background-color: rgba(255, 255, 255, 0.8);
      }
      &:after {
        content: '';
        flex-shrink: 0;
        width: 140px;
        height: 100%;
        background: url('../img/mp-big-arrow.png') no-repeat;
      }
    }
    &-product {
      &-list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        font-size: 26px;
        font-weight: bold;
      }
      &-item {
        margin-bottom: 35px;
        &:before {
          content: '✔';
          display: inline-block;
          width: 20px;
          text-align: center;
          margin-right: 15px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  /* work */
  &-work {
    &-item {
      text-align: center;
      width: 100%;
      padding: 0 10px;
      &-icon {
        width: 100%;
        max-width: 175px;
        margin: 0 auto 20px;
        padding-top: 50%;
        background-image: url(../img/icon-goal.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        &-calendar {
          background-image: url(../img/icon-calendar.png);
        }
        &-money {
          background-image: url(../img/icon-money.png);
        }
      }
      &-title {
        min-height: 2.5em;
        margin-top: 0;
        margin-bottom: 20px;
        font-size: 22px;
        font-weight: normal;
      }
      &-text {
        margin: 0;
        font-size: 16px;
      }
    }
  }

  /* catalog */
  &-catalog {
    background-color: #f8f8f8;
    h2 {
      text-align: center;
    }
    &-list {
      margin-bottom: 60px;
    }
    &-item {
      margin: 15px 10px;
      padding: 20px 15px;
      background-color: #ffffff;
      box-shadow: 1.6px 2.5px 3px rgba(0, 0, 0, 0.15);
    }
    &-img {
      display: block;
      max-width: 100%;
    }
    &-footer {
      margin-top: 20px;
      font-size: 20px;
      text-align: center;
    }
  }

  /* order */
  &-order {
    background-image: url('../img/magpack-contact-bg-1.jpg');
    background-size: cover;
    text-align: center;
    &-form {
      max-width: 740px;
      margin: 0 auto;
    }
    &-info {
      margin-top: 0;
      margin-bottom: 20px;
      font-size: 12px;
      &-or {
        display: block;
        margin: 40px 0;
        font-size: 28px;
      }
    }
    &-phone {
      font-size: 60px;
      font-weight: 500;
      color: #ffffff;
    }
  }

  /* clear */
  &-clear {
    background-color: #034c8e;
    &-title {
      font-size: 24px;
      margin-bottom: 40px;
    }
    &-text {
      font-size: 16px;
      line-height: 2;
    }
    &-img {
      display: block;
      margin: -40px -10px -55px -10px;
      width: calc(100% + 20px);
      height: calc(100% + 95px);
      object-fit: cover;
    }
  }

  /* partners */
  &-partners {
    &-item {
      padding: 0 30px;
      &-logo {
        display: flex;
        align-items: center;
        min-height: 100px;
        margin-bottom: 20px;
        &-img {
          display: block;
          max-width: 100%;
          margin: 0 auto;
        }
      }
      &-info {
        font-size: 14px;
        &-person {
          font-size: 16px;
          font-style: italic;
        }
      }
    }
  }

  /* call us */
  &-call-us {
    background-image: url('../img/magpack-contact-bg-2.jpg');
    background-size: cover;
    text-align: center;
    &-form {
      max-width: 740px;
      margin: 0 auto;
    }
    &-info {
      margin-top: 0;
      margin-bottom: 20px;
      font-size: 12px;
      &-or {
        display: block;
        margin: 40px 0;
        font-size: 28px;
      }
    }
    &-phone {
      font-size: 60px;
      font-weight: 500;
      color: #ffffff;
    }
  }

  /* contacts */
  &-contacts {
    &-map {
      width: 100%;
      height: 550px;
    }
    &-title {
      margin-top: 0;
      margin-bottom: 30px;
      font-size: 36px;
    }
    &-company {
      font-size: 18px;
    }
    &-content {
      position: absolute;
      bottom: 0;
      left: 0;
      max-width: 470px;
      padding: 50px 40px 10px 40px;
      background-color: #ffffff;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }
    &-info {
      border-bottom: 5px solid #034c8e;
    }
  }

  &-summa {
    display: block;
    width: 100%;
    text-align: right;
    font-weight: bold;
  }
  &-sending h2 {
    margin: 14px 0;
    font-size: 14px;
  }

  /* thank */
  &-thank {
    min-height: 100vh;
    background-image: url(../img/thanks-bg.jpg);
    color: #ffffff;
    display: flex;
    flex-direction: column;
    &-contact-phone {
      color: #ffffff;
      font-size: 30px;
    }
    &-header {
      align-self: flex-start;
      width: 100%;
      padding: 40px 0 10px 0;
      .mp-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .logo {
        width: 146px;
        height: 24px;
        background: url(../img/logo2x.png) 0 0 no-repeat;
        background-size: 146px 24px;
      }
    }
    &-content {
      margin: auto;
    }
    &-title {
      font-size: 72px;
      font-weight: normal;
      margin: 0 0 40px 0;
      text-transform: uppercase;
      text-align: center;
    }
    &-text {
      font-size: 36px;
      margin: 0;
      text-align: center;
    }
  }

  /* products */

  &-product-list {
    &.mp-cropped {
      .card.item:nth-child(n + 5) {
        position: absolute;
        overflow: hidden;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        padding: 0;
        border: 0;
      }
    }
  }
  &-load-more {
    &:not(.less) .less {
      display: none;
    }
    &.less .more {
      display: none;
    }
  }
}

.confidential-information-popup {
  max-width: 980px;
  font-size: 12px;
  p {
    font-size: inherit;
    margin: 16px 0;
  }
}

.absolute-input {
  position: absolute;
  left: -9999px;
  display: none;
}

.absolute-input-1 {
  position: absolute;
  left: -9999px;
}

.mp-link {
  cursor: pointer;
}

.page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 154px);
}

.form-error-below {
  text-align: center !important;
}

.edit-products-page {
  justify-content: flex-start;
}

.edit-products-page .heading {
  display: flex;
  justify-content: space-between;
  width: 100%;
  justify-self: flex-start;
  align-self: flex-start;
  padding: 1rem 2.7rem;
}

.edit-products-page .card {
  display: flex;
  row-gap: 5em;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0);
  transition: all 0.35s;
  margin: 0.5rem 1rem;
}

.edit-products-page .card:hover {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.edit-products-page .card .control-buttons {
  transition: all 0.35s 0.15s;
  opacity: 0;
  transform: scale(0.8);
}

.edit-products-page .material-card .control-buttons {
  opacity: 1;
  transform: scale(1);
}

.edit-products-page .card:hover .control-buttons {
  opacity: 1;
  transform: scale(1);
}

.edit-products-page .card .item-price {
  font-size: 1.5rem;
  color: #242222;
}

.edit-products-page .card .control-buttons {
  margin: -0.4rem 0 0;
}

.edit-products-page .material-card .control-buttons {
  margin: 0;
}

.edit-products-page .add-product-button {
  font-size: 1.1rem;
}

.header-logout-button {
  position: absolute !important;
  top: 1rem;
  right: 1rem;
}

// product-modal-form

.product-modal-form {
  min-width: 38vw;
}

.product-modal-form .container {
  padding: 4em 2em 2em;
}

.product-modal-form .form .form-row,
.product-modal-form .form .form-row > div {
  width: 100%;
  min-width: 300px;
}

.product-delete-prompt {
  padding: 4em 2em 2em;
}

.product-delete-prompt h4,
.product-modal-form h4 {
  font-size: 1.5rem;
  margin: 0 0 1em;
  font-weight: normal;
}

@import './form-elements.scss';
@import './responsive.scss';
