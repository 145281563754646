@keyframes spinnerAnimation {
  100% {
    transform: rotate(1turn);
  }
}

.preloader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: rgba(255, 255, 255, 0.45);
}

.preloader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;

  &::before,
  &::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
  }

  &::before {
    z-index: 9999;
    width: 64px;
    height: 64px;
    border: 4px solid blue;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spinnerAnimation 1s linear infinite;
  }
}
