@media screen and (max-width: 1480px) {
  .site {
    padding-top: 95px;
  }
  .calculator-section {
    padding-top: 115px;
    margin-top: -80px;
  }
}

@media screen and (max-width: 1274px) {
  .site {
    padding-top: 127px;
  }
  .section {
    padding-top: 127px;
  }
  .header .logo {
    top: 33px;
  }
  .header-contents {
    padding-left: 230px;
    padding-right: 280px;
  }
  .header .phone-block {
    padding: 2em 0;
  }
  .calculator-section {
    padding-top: 130px;
    margin-top: -80px;
  }
}

@media screen and (max-width: 1320px) {
  .calculator-section {
    background-size: 296px 360px;
    background-position: left 97%;
    .form-value {
      .form-control {
        width: 70px;
        margin-right: 7px;
      }
    }
  }
}

@media screen and (max-width: 968px) {
  h1 {
    font-size: 35px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 21px;
  }
  h4 {
    font-size: 17px;
  }
  p {
    font-size: 17px;
  }
  .header {
    position: relative;
    &-contents {
      padding-top: 100px;
      padding-left: 1.8em;
      padding-right: 90px;
    }
    .menu-links li {
      display: block;
      margin: 0.8em 0.7em;
    }
    .menu-link {
      font-size: 0.9em;
    }
    .phone-number {
      font-size: 1.3em;
      font-weight: 400;
    }
  }
  .site {
    padding-top: 0;
  }
  .mobile-only-hidden {
    display: none;
  }

  .top-section {
    box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.4) inset;
    .packbob {
      position: relative;
      left: 5%;
    }
    .slogan {
      text-align: center;
      h1 {
        font-size: 33px;
        margin-bottom: 20px;
      }
      p {
        font-size: 23px;
      }
    }
    .row {
      top: 0;
    }
  }
  .calculator-section {
    background-image: none;
    .container {
      margin: 0 auto;
    }
    .form {
      max-width: 70%;
      margin: 30px auto 0;
      .form-row {
        margin-bottom: 14px;
      }
      .form-label {
        width: 40%;
        text-align: left;
        margin-right: 1%;
        &-long {
          width: 188px;
        }
      }
      .form-value {
        width: 55%;
        text-align: left;
        .form-control {
          margin-right: 7px;
          text-align: center;
          max-width: 115px;
        }
      }
    }
    .calculated-price {
      font-size: 19px;
      margin: 0 auto;
    }
    &.counted {
      .calculated-price {
        margin: 10px auto 25px;
      }
    }
  }
  .reasons-section {
    padding: 0 2%;
  }
  .mission-section {
    padding: 0;
    background-image: url(../img/cleaner-world.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.4) inset;
    .text-part {
      font-size: 22px;
      line-height: 34px;
      padding: 55px 0;
    }
    p {
      font-size: 20px;
      line-height: 29px;
    }
  }
  .section {
    .form {
      font-size: 17px;
    }
  }
  .catalog-section {
    padding-top: 30px;
    .card {
      padding: 15px;
      font-size: 15px;
      h4 {
        margin-bottom: 6px;
        font-size: 17px;
      }
    }
  }
  .order-panel {
    padding-top: 121px;
    &-top {
      min-height: 121px;
    }
    .info-and-button {
      width: 100%;
    }
  }
  .with-order .order-panel {
    height: 117px;
    max-height: 117px;
    &.opened {
      height: auto;
      max-height: 70%;
    }
  }
  .popup-form {
    .close-popup {
      opacity: 0;
      transform: scale(0);
    }
    &.popup-opened {
      width: 100%;
      height: 100%;
      padding: 50px 30px;
      &-heading {
        font-size: 24px;
      }
      .close-popup {
        top: 15px;
        right: 20px;
        font-size: 40px;
        opacity: 1;
        transform: scale(1);
      }
    }
  }
  .share-section {
    padding: 0;
    height: 0;
    h2 {
      display: none;
    }
  }
}

@media screen and (max-width: 425px) {
  h2 {
    font-size: 24px;
  }
  .header {
    padding-bottom: 1em;
    .logo {
      top: 26px;
      left: 25px;
    }
    .phone-block {
      right: auto;
      text-align: left;
      left: 23px;
      top: 50px;
    }
    .to-order-form-link {
      margin-top: 2em;
    }
  }
  .section {
    .form {
      font-size: 15px;
    }
    .bottom-link {
      font-size: 16px;
    }
    &.parallax {
      padding: 35px 0;
      &.map-section {
        padding: 0;
      }
    }
  }
  .top-section {
    .slogan {
      text-align: left;
    }
    .packbob {
      left: -50px;
      top: 110px;
      background-repeat: no-repeat;
      background-position: 2% 80%;
      background-size: 60%;
      img {
        width: 60%;
      }
    }
  }
  .calculator-section {
    overflow: hidden;
    .form {
      max-width: 100%;
      padding: 0 20px;
    }
  }
  .mission-section {
    &.parallax {
      padding: 0;
    }
    .text-part {
      line-height: 30px;
      padding: 40px 0;
    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
  }
  .contact-section {
    .property {
      margin: 15px 0;
      font-size: 17px;
    }
  }
  .catalog-section {
    .card {
      flex-wrap: wrap;
      padding: 13px;
      font-size: 14px;
      h4 {
        margin-bottom: 6px;
        font-size: 16px;
      }
      .item-photo {
        width: 100%;
        max-width: none;
        margin-bottom: 15px;
      }
      .item-description {
        width: 100%;
      }
      .item-properties {
        line-height: 23px;
      }
      .item-price {
        padding: 3px 0;
      }
      .control-buttons {
        text-align: center;
        .form-control {
          width: 87px;
        }
        .summa {
          font-size: 21px;
        }
      }
    }
  }
  .order-panel {
    max-height: 70%;
    padding-top: 132px;
    &-top {
      padding-top: 5px;
      min-height: 132px;
      .added {
        display: inline-block;
        font-size: 17px;
        padding: 8px 0 0;
      }
    }
    &-content {
      .item {
        flex-direction: column;
        padding: 10px 0;
        &-photo {
          width: 60px;
          height: 60px;
        }
        .form-control {
          width: 100px;
        }
      }
    }
    .info-and-button {
      .button {
        height: 50px;
      }
    }
    .remove-item {
      position: absolute;
      top: 20px;
      right: 0;
    }
  }
  .with-order .order-panel {
    &.opened {
      max-height: 70%;
    }
  }

  .slick-dots li {
    padding: 8px;
  }
}

.desktop {
  .desktop-nowrap {
    display: inline-block;
    white-space: nowrap;
  }
}

@media screen and (max-width: 425px) {
  .header {
    padding: 20px;
    flex-direction: column;
    &-contents {
      padding: 20px 0;
    }
    .logo {
      position: static;
    }
    .menu {
      display: none;
    }
    .phone-block {
      position: relative;
      top: 0;
      left: 0;
      padding: 0;
    }
  }
  .mp {
    &-container {
      padding: 0 20px;
    }
    &-section {
      &-header {
        margin-bottom: 24px;
      }
      &-title {
        font-size: 24px;
      }
    }
    &-hero {
      &-content {
        flex-direction: column;
        &-info {
          margin-right: 0;
        }
      }
      &-title {
        margin-bottom: 18px;
      }
      &-arrow {
        display: none;
      }
      &-product {
        &-list {
          margin-bottom: 18px;
          font-size: 16px;
        }
        &-item {
          margin-bottom: 12px;
        }
      }
      &-form {
        width: auto;
      }
    }
    &-clear {
      &-title {
        margin-bottom: 20px;
      }
      &-img {
        width: 100%;
        height: auto;
        margin: 0;
      }
    }
    &-work-item {
      margin-bottom: 18px;
      padding: 0;
      &-title {
        min-height: auto;
      }
    }
    &-partners {
      &-item {
        margin-bottom: 52px;
        padding: 0;
        &-logo {
          min-height: auto;
        }
      }
    }
    &-order {
      &-info-or {
        margin: 20px 0;
        font-size: 24px;
      }
      &-phone {
        font-size: 32px;
      }
    }
    &-contacts {
      &-map {
        height: 300px;
      }
      &-content {
        position: static;
        padding: 30px 0 10px 0;
        border-radius: 0;
      }
    }
    &-thank {
      &-contact-phone {
        font-size: 22px;
      }
      &-header {
        padding-top: 20px;
        .logo {
          margin-bottom: 20px;
        }
        .mp-container {
          display: block;
        }
      }
      &-title {
        margin-bottom: 20px;
        font-size: 24px;
      }
      &-text {
        font-size: 16px;
      }
    }
  }
}
