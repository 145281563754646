html,
body {
  margin: 0;
  padding: 0;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

// flex grid
.container {
  max-width: 1280px;
  padding: 0 10px;
  margin: 0 auto;
  @media screen and (max-width: 1320px) {
    & {
      max-width: 87%;
    }
  }
  @media screen and (max-width: 968px) {
    & {
      max-width: 94%;
    }
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  margin: 0 -10px;
  .col {
    margin: 0 10px;
  }
  &-end {
    justify-content: flex-end;
  }
}

// flex grid responsiveness
.col-l-1 {
  width: calc(8.33% - 20px);
}
.col-l-2 {
  width: calc(16.66% - 20px);
}
.col-l-3 {
  width: calc(25% - 20px);
}
.col-l-4 {
  width: calc(33.33% - 20px);
}
.col-l-5 {
  width: calc(41.66% - 20px);
}
.col-l-6 {
  width: calc(50% - 20px);
}
.col-l-7 {
  width: calc(58.33% - 20px);
}
.col-l-8 {
  width: calc(66.66% - 20px);
}
.col-l-9 {
  width: calc(74% - 20px);
}
.col-l-10 {
  width: calc(83.33% - 20px);
}
.col-l-11 {
  width: calc(91.66% - 20px);
}
.col-l-12 {
  width: calc(100% - 20px);
}

@media screen and (max-width: 1320px) {
  .col-m-1 {
    width: calc(8.33% - 20px);
  }
  .col-m-2 {
    width: calc(16.66% - 20px);
  }
  .col-m-3 {
    width: calc(25% - 20px);
  }
  .col-m-4 {
    width: calc(33.33% - 20px);
  }
  .col-m-5 {
    width: calc(41.66% - 20px);
  }
  .col-m-6 {
    width: calc(50% - 20px);
  }
  .col-m-7 {
    width: calc(58.33% - 20px);
  }
  .col-m-8 {
    width: calc(66.66% - 20px);
  }
  .col-m-9 {
    width: calc(74% - 20px);
  }
  .col-m-10 {
    width: calc(83.33% - 20px);
  }
  .col-m-11 {
    width: calc(91.66% - 20px);
  }
  .col-m-12 {
    width: calc(100% - 20px);
  }
}

@media screen and (max-width: 968px) {
  .col-s-1 {
    width: calc(8.33% - 20px);
  }
  .col-s-2 {
    width: calc(16.66% - 20px);
  }
  .col-s-3 {
    width: calc(25% - 20px);
  }
  .col-s-4 {
    width: calc(33.33% - 20px);
  }
  .col-s-5 {
    width: calc(41.66% - 20px);
  }
  .col-s-6 {
    width: calc(50% - 20px);
  }
  .col-s-7 {
    width: calc(58.33% - 20px);
  }
  .col-s-8 {
    width: calc(66.66% - 20px);
  }
  .col-s-9 {
    width: calc(74% - 20px);
  }
  .col-s-10 {
    width: calc(83.33% - 20px);
  }
  .col-s-11 {
    width: calc(91.66% - 20px);
  }
  .col-s-12 {
    width: calc(100% - 20px);
  }
}

@media screen and (max-width: 425px) {
  .col {
    width: calc(100% - 20px);
  }
}
