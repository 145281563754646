.button {
  display: inline-block;
  padding: 0.9em 1.9em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  outline: none;
  border: none;
  opacity: 1;
  background-color: #d50321;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  letter-spacing: 2px;
  height: 60px;
  transition: background-color ease-out 0.4s;
  border-bottom: 2px solid #ac031b;
  &:hover,
  &:focus {
    color: #fff;
    background-color: #bb0d27;
    transition: background-color ease-out 0.4s;
    border-bottom: 2px solid #ac031b;
  }
  &-blue {
    background-color: #076eca;
    border-bottom: 2px solid #034c8e;
    &:hover,
    &:focus {
      background-color: #034c8e;
      border-bottom: 2px solid #034c8e !important;
    }
  }
  &-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.8em 2.2em;
  }
  &-big {
    font-size: 21px;
    height: 75px;
  }
  .fa {
    font-size: 22px;
    vertical-align: middle;
  }
  .card & {
    height: 45px;
    padding: 0 17px 0 10px;
    font-size: 17px;
    .fa {
      font-size: 14px;
    }
  }
  &-small {
    height: 45px;
    padding: 0 20px;
    font-size: 17px;
  }
}

.form-control {
  padding: 0.7em 1.2em;
  font-size: 1em;
  border: 1px solid #e9e9e9;
  transition: all 0.35s;
  &:hover,
  &:focus {
    border-color: #a3a3a4;
    outline: none;
  }
  &:focus {
    border-color: #63a9e7;
  }
}

.form {
  .form-row {
    margin-bottom: 25px;
  }
  .form-label,
  .form-value {
    display: inline-block;
    vertical-align: middle;
  }
  .form-control {
    width: 100%;
    z-index: 1;
    &.message-textarea {
      height: 110px;
      resize: none;
    }
  }
  .success-message,
  .error {
    color: inherit;
    font-size: inherit;
  }
  .success-message {
    text-align: center;
    width: 100%;
    margin: 16px 0 0;
  }
  .error {
    font-size: 14px;
    color: #ff0658;
  }
  .with-error {
    .form-control {
      border-color: #ff0658;
    }
    .error {
      padding: 10px 0;
    }
  }
  &.sent {
    .button-send {
      background-color: #a7a7a7;
    }
    .form-control {
      border-color: #ccc;
    }
  }
  .row {
    margin: 0;
  }
  .property-unit {
    color: #999;
  }
}
.form-row {
  position: relative;
  .placeholder {
    position: absolute;
    top: 15px;
    left: 25px;
    opacity: 0;
    transition: all ease-out 0.25s;
    font-size: inherit;
    font-size: 14px;
  }
  &.input {
    .form-control {
      padding-top: 30px;
      padding-bottom: 10px;
    }
    .placeholder {
      top: 6px;
      opacity: 1;
      transition: all 0.15s;
      z-index: 1;
    }
  }
}

.popup-form {
  .form-control {
    padding: 12px 17px;
  }
  .popup-buttons {
    text-align: center;
  }
}

@media screen and (max-width: --s-width) {
  .form-control {
    padding: 8px 14px;
    font-size: 14px;
  }
  .with-error {
    .error {
      padding: 7px 0;
    }
  }
  .form-row {
    position: relative;
    .form-control,
    .button-block {
      height: 50px;
    }
    .placeholder {
      top: 16px;
      left: 14px;
      font-size: 12px;
    }
    .input {
      .form-control {
        padding-top: 14px;
        padding-bottom: 6px;
      }
      .placeholder {
        top: 4px;
      }
    }
  }
  .button-send {
    font-size: 14px;
  }

  .button {
    .card & {
      height: 40px;
      padding: 0 10px 0 6px;
      font-size: 15px;
    }
    &-big {
      height: 60px;
    }
  }
}

@media screen and (max-width: --xs-width) {
  .form-row {
    .form-control,
    .button-block {
      height: 45px;
      padding: 6px 12px;
    }
  }
  .button {
    height: 50px;
    font-size: 16px;
    &-big {
      height: 56px;
      font-size: 18px;
    }
    &-small {
      height: 35px;
      padding: 0 18px;
      font-size: 15px;
    }
  }
}

// form validation
.contact-form label.error,
div#errors {
  border: 1px solid #f07f73 !important;
  margin-bottom: 3px;
  background: #f07f73;
  padding-left: 5px;
  color: #ffffff;
  font-family: 'Open Sans', 'sans-serif';
}

div#errors {
  display: none;
}

.contact-form #msg_submitting {
  display: none;
}

.contact-form #msg_submitted {
  display: none;
}

// button animated
.button-big,
.contact-button {
  position: relative;
  .pulse {
    width: 10px;
    height: 10px;
    border: 5px solid #f7f14c;
    border-radius: 30px;
    background-color: #716f42;
    z-index: 10;
    position: absolute;
    opacity: 0;
  }
  .dot {
    border: 60px solid #f85c17;
    background: transparent;
    border-radius: 50%;
    height: 200px;
    width: 200px;
    animation: pulse 2.5s ease-in;
    animation-iteration-count: infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    opacity: 0;
    margin: -100px 0 0 -110px;
  }

  @keyframes pulse {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    15% {
      transform: scale(0.1);
      opacity: 0;
    }
    25% {
      opacity: 0.5;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
}
